<template>
  <div>
    <EnvieHeader color="purple" :filter-id="filterId" />
    <Breadcrumb class="container mt-4" />
    <div class="container tags-search">
      <div class="row flex-md-row-reverse">
        <div class="col-12 col-md-6">
          <Searchform v-model="terms" button-color="medium-purple" />
        </div>
        <div class="col-12 col-md-6 pt-4 pt-md-0">
          <Tags
            color="medium-purple"
            :selected="filterId"
            :tags="filters"
          >
            <template #tag="{ item }">
              <router-link :to="{ name: 'enviede', params: { partnerId, filterId: item.id }, hash: '#products' }">
                {{ item.getName($l) }}
              </router-link>
            </template>
          </Tags>
        </div>
      </div>
    </div>
    <a id="products"></a>
    <div
      v-for="(shop, index) in shops"
      :key="shop.id"
      class="shop-item"
      :class="{ 'last-section': index === shops.length - 1 }"
    >
      <EnvieDePageSlider
        :title="shop.name"
        :shop-id="shop.id"
        :shop-object="shop"
        :filter-id="filterId"
        :terms="terms"
        color-bg="yellow"
        color-arrows="purple"
      />
    </div>
    <div v-if="!shops.length && filterId && !loading" class="pt-5 mt-5 pb-lg-5 last-section">
      {{ $t('enviede.noproduct') }}
    </div>
  </div>
</template>

<script>
import EnvieHeader from '@/components/EnvieHeader.vue'
import Searchform from '@/components/UI/Searchform.vue'
import Tags from '@/components/UI/Tags.vue'
import EnvieDePageSlider from '@/components/Sliders/EnvieDePageSlider.vue'
import Breadcrumb from '@/components/UI/Breadcrumb.vue'
import ScrollPromise from '@/mixins/ScrollPromise'

export default {
  name: 'EnvieDe',
  components: {
    EnvieHeader,
    Searchform,
    Tags,
    EnvieDePageSlider,
    Breadcrumb
  },
  mixins: [ScrollPromise],
  props: {
    partnerId: { type: String },
    filterId: { type: String }
  },
  data: () => ({
    loading: false,
    terms: null,
    shops: []
  }),
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    filters() {
      return this.$store.getters['partner/filters'].filter(f => f.mode !== 'favourite')
    }
  },
  watch: {
    filterId: 'load'
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.shops = []
      return Promise
        .all([
          this.$store.dispatch('filter/listShops', { id: this.filterId, partnerId: this.partnerId }),
          this.$store.dispatch('partner/filters', { id: this.partnerId })
        ])
        .then(res => {
          this.shops = res[0]
          this.scrollInitialized = true
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss">
@import '../assets/styles/youpaq.scss';

/* tags search */
.tags-search {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }

  @include media-breakpoint-up(lg) {
    margin: 50px auto 50px;
  }

  .flex-md-row-reverse {
    width: 100%;
  }
}

body {
  margin: 0;
}
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3e3e3d;
}

.shop-item {
  margin-top: 75px;
  padding: 0;

  @include media-breakpoint-up(lg) {
    margin: 80px 0;
    padding: 70px 0;
  }
}

.last-section {
  padding-bottom: 200px !important;
}
</style>
