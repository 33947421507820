<template>
  <div id="envie-header">
    <div
      class="content"
      :color="color"
    >
      <div class="content-image">
        <img
          class="d-md-none"
          :src="$resize(filter.images.raw.url, { w: 720, h: 504 })"
          alt="mobile"
          title="mobile"
        >
        <img
          class="d-none d-md-flex"
          :src="$resize(filter.images.raw.url, { w: 720, h: 504 })"
          alt="desktop"
          title="desktop"
        >
      </div>
      <div v-if="filter" class="content-text">
        <h2>{{ $t('enviede.title') }}</h2>
        <div class="category">
          {{ filter.getName($l) }}
        </div>
        <div v-marked="filter.getDescription($l)" class="d-none d-md-block"></div>
      </div>
    </div>
    <div v-marked="filter.getDescription($l)" class="d-md-none pt-4 text-mobile"></div>
  </div>
</template>

<script>

export default {
  name: 'EnvieHeader',
  props: {
    filterId: {
      type: String
    },
    color: {
      type: String,
      default: 'purple'
    }
  },
  computed: {
    filter() {
      const filters = this.$store.getters['partner/filters']
      return filters.find(filter => filter.id === this.filterId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

#envie-header {
  background-color: var(--yellow-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 360px;

  @include media-breakpoint-up(md) {
    min-height: 480px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 800px;
  }

.content[color="purple"] {
    .content-text .category {
      color: var(--medium-purple-color);
    }
  }
.content[color="yellow"] {
    .content-text .category {
      color: var(--yellow-color);
    }
  }
.content[color="red"] {
    .content-text .category {
      color: var(--red-color);
    }
  }
.content[color="green"] {
    .content-text .category {
      color: var(--green-color);
    }
  }

  .content {
    background-color: white;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px 1px 7px #0000005e;
    position: relative;
    width: 90%;
    min-height: 100px;

    @include media-breakpoint-up(md) {
      min-height: 250px;
      max-width: 675px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 450px;
      max-width: 1200px;
    }
  }

  .content-text {
    width: 45%;
    margin-right: 55%;
    font-weight: 300;
    color: var(--grey-color);
    text-align: left;
    padding: 10px 5px;
    box-sizing: border-box;

    @include media-breakpoint-up(md) {
      font-size: 15px;
      padding: 20px;
    }

    @include media-breakpoint-up(lg) {
      width: 40%;
      margin-right: 55%;
      font-size: 18px;
      padding: 50px 50px 50px 0;
    }

    h2 {
      font-weight: 900;
      font-size:20px;
      text-transform: uppercase;
      margin: 0;

      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }
    }
    .category {
      font-size: 20px;
      line-height: 22px;
      font-weight: 900;

      @include media-breakpoint-up(md) {
        font-size: 40px;
        line-height: 40px;
        padding: 0;
      }

      @include media-breakpoint-up(lg) {
        font-size: 70px;
        line-height: 70px;
      }
    }
  }

  .content-image {
    width: 190px;
    position: absolute;
    top: -10px;
    z-index: 2;

    @include media-breakpoint-up(md) {
      width: 395px;
      right: -30px;
      top: -18px;
    }

    @include media-breakpoint-up(lg) {
      width: 720px;
      top: -40px;
    }

    img {
      width: 100%;
      transform: rotate(10deg);
    }
  }
  .text-mobile {
    color: white;
    font-weight: 400;
    padding: 0 20px;
  }
}
</style>
