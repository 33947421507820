<template>
  <div
    class="tags"
    :color="color"
  >
    <div
      v-for="item in tags"
      :key="item[valueField]"
      class="tag-item"
      :class="{ 'tag-selected': item[valueField] === selected }"
    >
      <slot name="tag" v-bind="{ item }">
        {{ item[textField] }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    color: {
      type: String,
      default: 'yellow'
    },
    selected: {
      type: [String, Number]
    },
    tags: {
      type: Array
    },
    valueField: {
      type: String,
      default: 'id'
    },
    textField: {
      type: String,
      default: 'text'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
}
.tag-item {
  font-size: 14px;
  font-weight: 700;
  background-color: var(--yellow-color);
  color: white;
  padding: 5px 12px;
  margin: 3px 3px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    font-size: 17px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
.tag-item.tag-selected {
  background-color: var(--red-color) !important;
  color: white;
  border: none;
}

.tags[color = "yellow"] {
  .tag-item {
    background-color: var(--yellow-color);
  }
}
.tags[color = "red"] {
  .tag-item {
    background-color: var(--red-color);
  }
}
.tags[color = "purple"] {
  .tag-item {
    background-color: var(--purple-color);
  }
}
.tags[color = "medium-purple"] {
  .tag-item {
    background-color: var(--medium-purple-color);
  }
}
.tags[color = "green"] {
  .tag-item {
    background-color: var(--green-color);
  }
}
.tag-selected {
  border: 1px solid black;
  background-color: white;
  color: black;
}
</style>
