let interval = null
let step = 0
let vm = null

const intervalTime = 100
const intervalMax = 5000

const clear = () => {
  vm = null
  window.clearInterval(interval)
}

const setScrollPromiseForRoute = to => {
  // in same tick, create scroll promise
  if (vm) {
    vm.scrollInitialized = false
  }

  // define scroll promise
  to.meta.scrollPromise = new Promise((resolve, reject) => {
    interval = window.setInterval(() => {
      // try each [x] milliseconds to see if vm is initialized or not
      // and resolve promise if ok (or waiting too long)
      if ((vm && vm.scrollInitialized) || intervalTime * step >= intervalMax) {
        clear()
        resolve()
      }
      step += 1
    }, intervalTime)
  })
}

export default {

  data: () => ({
    scrollInitialized: false
  }),

  beforeRouteEnter(to, from, next) {
    // call next() first so we can reference the vm component in scroll promise
    next(v => (vm = v))
    setScrollPromiseForRoute(to)
  },

  beforeRouteUpdate(to, from, next) {
    // set scroll promise before next(), because we already are in component
    vm = this
    setScrollPromiseForRoute(to)
    next()
  },

  beforeRouteLeave(to, from, next) {
    clear()
    next()
  }
}
