<template>
  <div
    class="envie-de"
    :class="{ empty: !loading && !products.length }"
    :color-bg="colorBg"
    :color-arrows="colorArrows"
  >
    <div class="decorator-box">
      <h2>{{ title }}</h2>
    </div>
    <carousel-3d
      v-if="products.length"
      :disable3d="true"
      :clickable="false"
      :controls-visible="true"
      :width="300"
      :height="475"
      :display="3"
      :space="350"
    >
      <slide
        v-for="(product, index) in products"
        :key="product.id"
        :index="index"
      >
        <ProduitItem
          hide-title
          :shop-id="shopId"
          :shop-object="shopObject"
          :product-id="product.id"
          :product="product"
          :offer="product.reseller_offers[0]"
          :image="product.images && product.images.raw.url"
          :favourite.sync="product.favourite"
        />
      </slide>
    </carousel-3d>
    <div v-else-if="loading">{{ $t('content.loading') }}</div>
    <div v-else>{{ $t('enviede.noProduct') }}</div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import ProduitItem from '@/components/Produits/ProduitItem.vue'

export default {
  name: 'EnvieDePageSlider',
  components: {
    ProduitItem,
    Carousel3d,
    Slide
  },
  props: {
    title: {
      type: String
    },
    filterId: {
      type: String
    },
    shopId: {
      type: String
    },
    shopObject: {
      type: Object
    },
    terms: {
      type: String
    },
    colorBg: {
      type: String,
      default: 'yellow'
    },
    colorArrows: {
      type: String,
      default: 'purple'
    }
  },
  data: () => ({
    loading: false,
    offset: 0,
    limit: 50,
    done: false,
    products: []
  }),
  watch: {
    terms() {
      this.products = []
      this.offset = 0
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store
        .dispatch('filter/listProducts', {
          id: this.filterId,
          shopId: this.shopId,
          terms: this.terms,
          offset: this.offset,
          limit: this.limit
        })
        .then(products => {
          this.products = this.products.concat(products)
          this.offset += this.limit
          this.done = products.length < this.limit
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* Overwritten Carousel 3d style*/
.carousel-3d-container {
  margin-top: 35px !important;
  margin-bottom: 0;
  padding-top: 10px;
  height: 457px;

  @include media-breakpoint-up(md) {
    margin-top: 60px !important;
    height: 475px;
  }
}

.carousel-3d-slide {
  border: none !important;
  background: white !important;
  overflow: visible !important;
}

.envie-de .carousel-3d-slide:not(.current) {
  @include media-breakpoint-down(lg) {
    opacity:abs($number: 0.1) !important;
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}

::v-deep .prev,
::v-deep .next {
  color: transparent !important;
  width: 70px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  top: -70px;

  @include media-breakpoint-up(lg) {
    top: -35px;
  }
}
::v-deep .prev {
  background: url("~@/assets/slider-prev-purple.svg") no-repeat;
  left: 5px;
  @include media-breakpoint-up(md) {
    left: calc(50vw - 220px) !important;
  }
  @include media-breakpoint-up(lg) {
    left: calc(15% - 150px) !important;
  }
}

::v-deep .next {
  background: url("~@/assets/slider-next-purple.svg") no-repeat;
  background-position-x: right;
  right: 5px;
  @include media-breakpoint-up(md) {
    right: calc(50vw - 220px) !important;
  }
  @include media-breakpoint-up(lg) {
    right: calc(15% - 150px) !important;
  }
}

.envie-de {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;

  @include media-breakpoint-up(lg) {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
  }
}

.decorator-box {
  position: absolute;
  min-height: 400px;
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(lg) {
    min-height: 600px;
    width: 54% !important;
    margin: 0 auto;
    margin-top: -25px;
    border: 15px solid var(--yellow-color);
    position: absolute;
  }

  h2 {
    position: absolute;
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    top: -30px;
    z-index: 2;
    padding: 0 20px;
    margin: 0 auto;
    min-height: 60px;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(md) {
      font-size: 35px;
    }

    @include media-breakpoint-up(lg) {
      background-color: white;
      font-size: 40px;
      padding: 0 20px;
      top: -43px;
    }
  }
}

a {
  text-decoration: none;
}

.envie-de[color-bg = "yellow"] {
  .decorator-box {
    @include media-breakpoint-up(lg) {
      border: 15px solid var(--yellow-color);
    }
  }
}
.envie-de[color-bg = "medium-purple"] {
  .decorator-box {
    @include media-breakpoint-up(lg) {
      border: 15px solid var(--medium-purple-color);
    }
  }
}
.envie-de[color-bg = "red"] {
  .decorator-box {
    @include media-breakpoint-up(lg) {
      border: 15px solid var(--red-color);
    }
  }
}
.envie-de[color-bg = "green"] {
  .decorator-box {
    @include media-breakpoint-up(lg) {
      border: 15px solid var(--green-color);
    }
  }
}
</style>
